import React from "react";
import { authBloc, AuthState } from "@hai/bloc/auth";
import { loginFlow } from "@hai/bloc/nav";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import ResetPasswordLayout from "@components/ResetPasswordLayout";

const ResetPassword = ({ onSuccess }) => {
  const history = useHistory();
  const methods = useForm();
  const email = authBloc.getEmail();
  const headerInfo = {
    title: "Reset Password",
    subtitle:
      "An email with a confirmation code was just sent to " +
      email +
      ". Please enter it below.",
  };

  const onCancel = () => {
    loginFlow.cancel();
    if (authBloc.authState.current === AuthState.LoggedIn) history.goBack();
  };

  return (
    <ResetPasswordLayout
      methods={methods}
      onSuccess={onSuccess}
      onCancel={onCancel}
      headerInfo={headerInfo}
    />
  );
};

export default ResetPassword;

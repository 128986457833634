import React, { useState } from "react";
import Layout from "@components/Layout";
import FormLayout from "@components/FormLayout";
import Button from "@components/Button";
import TextField from "@components/TextField";
import ResendCode from "@components/ResendCode";
import { useForm } from "react-hook-form";
import { authBloc } from "@hai/bloc/auth";
import { loginFlow } from "@hai/bloc/nav";
import { ResendCodeFlow } from "@utils/constants";

const Verification = () => {
  const methods = useForm();
  const [processing, setIsProcessing] = useState(false);

  const onFormSubmit = (data) => {
    setIsProcessing(true);
    console.log(data); // Verify using data.code
    authBloc.confirmSignup(data.code).catch((error) => {
      setIsProcessing(false);
      methods.setError("code", {
        type: "incorrect", // type doesn't matter. we are not using type as of now
        message:
          "Confirmation code incorrect. Enter the correct code or try again.",
      });
    });
  };

  const onResendCodeError = (message) => {
    methods.setError('code', {
      type: "incorrect",
      message: message,
    })
  }

  return (
    <Layout>
      <FormLayout
        methods={methods}
        onSubmit={onFormSubmit}
        title="Enter Confirmation Code"
        subtitle={`An email with a confirmation code was just sent to ${authBloc.getEmail()}. Please enter it below:`}
        showDisclaimer={false}
      >
        <TextField
          name="code"
          label="Confirmation Code"
          type="text"
          validations={{
            required: {
              value: true,
              message: "Confirmation code cannot be empty",
            },
          }}
          bottomAction={<ResendCode type={ResendCodeFlow.SignUp} onError={onResendCodeError}/>}
        />
        <div className="mt-16">
          <Button
            value={processing ? "Submitting" : "Submit"}
            type="submit"
            disabled={processing}
            processing={processing}
          />
          <Button
            type="button"
            onClick={() => loginFlow.cancel()}
            transparent
            value="Cancel"
            disabled={processing}
          />
        </div>
      </FormLayout>
    </Layout>
  );
};

export default Verification;

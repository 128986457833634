import React, { useState } from "react";
import TextField from "@components/TextField";
import CheckboxField from "@components/CheckboxField";
import Button from "@components/Button";
import Layout from "@components/Layout";
import FormLayout from "@components/FormLayout";
import { emailValidations } from "@utils/constants";
import { authBloc, AuthBloc } from '@hai/bloc/auth';
import { useForm } from "react-hook-form";
import { loginFlow, LoginFlow } from "@hai/bloc/nav";
import axios from 'axios';
import * as config from "../hai.json";

const envConfig = Object.values(Object.values(config)[0])[0];

const CreateAccount = ({ history }) => {
  const methods = useForm();
  const [processing, setIsProcessing] = useState(false);
  const [subscribeToNewsLetter, setSubscribeToNewsLetter] = useState(true);

  const onSubmit = (data) => {
    setIsProcessing(true);
    console.log(data); // Create account with cognito using data.firstName, data.lastName etc.
    authBloc
      .signUp(
        data.email.trim(),
        data.password,
        data.firstName.trim(),
        data.lastName.trim(),
      )
      .catch(e => {
        setIsProcessing(false);
        if (e.code === 'UsernameExistsException') {
          methods.setError('email', {
            type: "focus",
            message: "An account already exists with this email address. Please login with that account.",
          });
          setTimeout(() => {
            loginFlow.notify(LoginFlow.screenSignin);
          }, 5000);
        } else if (e.code === 'InvalidPasswordException') {
          methods.setError('password', {
            type: "focus",
            message: e.message.split(':')[1],
          });
        } else {
          methods.setError('password', {
            type: "focus",
            message: 'Something went wrong. Please try again!',
          });
        }
      });
    if (subscribeToNewsLetter && data.email.trim()) {
      // Logic to subscribe to newsletter
      axios({
        method:'post',
        url:`https://api.gethai.com/${envConfig.env}/newsletter`,
        data:data.email.trim()
      }).then(()=>{
        console.log('Subscribed to newsletter');
      }).catch((error)=>{
        console.log('Failed to subscribe to newsletter',error);
      });
    }
  };

  return (
    <Layout>
      <FormLayout
        methods={methods}
        onSubmit={onSubmit}
        title="Create an Account"
        subtitle="Please fill out the information below:"
      >
        <TextFieldsList watch={methods.watch} />
        <div className="mt-5">
          <CheckboxField
            name="subscribeToNewsLetter"
            label="Subscribe to our newsletter."
            value={subscribeToNewsLetter}
            onChange={setSubscribeToNewsLetter}
          />
        </div>
        <div className="mt-10">
          <Button
            value={processing ? "Creating Account" : "Sign Up"}
            type="submit"
            disabled={processing}
            processing={processing}
          />
        </div>
        <div className="mt-5">
          <div
            className="text-center text-primary text-base cursor-pointer select-none"
            onClick={() => {loginFlow.signin()}}
          >
            Already have a hai account?
            <span className="font-medium"> Sign in {">"}</span>
          </div>
        </div>
      </FormLayout>
    </Layout>
  );
};
const TextFieldsList = ({ watch }) => {
  const textFields = [
    {
      name: "firstName",
      label: "First Name",
      type: "text",
      value: authBloc.getAttribute(AuthBloc.attributeFirstName),
      validations: {
        required: { value: true, message: "First name cannot be blank" },
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      value: authBloc.getAttribute(AuthBloc.attributeLastName),
      type: "text",
      validations: {
        required: { value: true, message: "Last name cannot be blank" },
      },
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: authBloc.getEmail(),
      validations: emailValidations,
      autoComplete:"username"
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      validations: {
        required: { value: true, message: "Password cannot be blank" },
        minLength: {
          value: 8,
          message: "Password must be 8 to 30 characters.",
        },
        maxLength: {
          value: 30,
          message: "Password must be 8 to 30 characters.",
        },
      },
      autoComplete:"new-password"
    },
    {
      name: "confirmPassword",
      label: "Confirm Password",
      type: "password",
      validations: {
        required: { value: true, message: "Password cannot be blank" },
        validate: (value) => {
          return value === watch("password") ? true : "Passwords don’t match.";
        },
      },
      autoComplete:"new-password"
    },
  ];
  return textFields.map((field, index) => (
    <TextField
      key={field.name}
      name={field.name}
      label={field.label}
      type={field.type}
      validations={field.validations}
      isLastField={index === textFields.length - 1}
      autocomplete={field.autoComplete}
    />
  ));
};

export default CreateAccount;

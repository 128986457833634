import React from "react";
import Logo from "@assets/images/logo.svg";

const Layout = ({ children }) => {
  console.log('render1');
  return (
    <div className="lg:flex lg:w-screen lg:h-screen bg-cream">
      <div
        className="flex flex-1 h-377px lg:h-full bg-cover bg-top py-5 px-6 lg:py-7"
        style={{ backgroundImage: "url('/may-look.jpg')" }}
      >
        <a className="self-start" href="https://gethai.com/">
          <img className="block h-10 w-auto" src={Logo} alt="logo" />
        </a>
      </div>
      <div className="flex flex-col flex-1 lg:h-full items-center pt-14 pb-40 px-8 lg:pt-16 2xl:pt-36">
        <div className="flex flex-col lg:w-3/4 xl:w-3/5">{children}</div>
      </div>
    </div>
  );
};

export default Layout;

import React from "react";
import Spinner from "@assets/images/spinner.svg";

const Button = ({
  value,
  onClick,
  disabled,
  processing,
  outline,
  transparent,
  ...props
}) => {
  const bgColor = outline || transparent ? "bg-transparent" : "bg-primary";
  const borderColor = outline ? "border-primary" : "border-transparent";
  const textColor = outline
    ? "text-primary"
    : transparent
    ? "text-grey"
    : "text-white";
  return (
    <button
      onClick={onClick}
      className={`h-11 w-full flex justify-center items-center text-15px leading-15px font-medium tracking-wide ${bgColor} ${borderColor} ${textColor} border-2 mt-3 ${
        transparent && "outline-none focus:outline-none active:opacity-50"
      }`}
      disabled={disabled}
      {...props}
    >
      {processing && (
        <img src={Spinner} className="h-full w-auto" alt="loading spinner" />
      )}{" "}
      <span>{value}</span>
    </button>
  );
};

export default Button;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {} from 'react';

const DeleteAccount = () => {
  return (
    <>
<div style={{ padding: '20px' }}>
  <div style={{textAlign: 'center', marginBottom: '20px'}}>
    <img width={92} height={92} src="logo.png" alt="Company logo" style={{display: 'block', margin: '0 auto'}} />
  </div>

  <h1 style={{textAlign: 'center', fontSize: '28px', fontWeight: 'bold', marginTop: '20px'}}>
    hai - smart spa-shower system
  </h1>

  {/* Rest of the code remains unchanged */}
  
  <p><span>&nbsp;</span></p>

  <p><span>We take your privacy seriously. Please follow the steps below to delete your account and data stored with us.</span></p>

  <p><span>&nbsp;</span></p>

  <p style={{marginLeft: '0.5in', textIndent: '-0.25in'}}>
    <span>●<span style={{fontSize: '7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
    <span>Open the hai app and navigate to "My hai" section. Tap on the Settings menu at the top right.</span>
  </p>

  <p style={{marginLeft: '0.5in'}}><span>&nbsp;</span></p>

  <div style={{textAlign: 'center'}}>
    <img width={142} height={307} src="del-1.png" alt="App screenshot 1" style={{display: 'block', margin: '0 auto'}} />
  </div>

  <p><span>&nbsp;</span></p>

  <p style={{marginLeft: '0.5in', textIndent: '-0.25in'}}>
    <span>●<span style={{fontSize: '7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
    <span>Scroll down and tap on the item 'Delete Account'.</span>
  </p>

  <p style={{marginLeft: '0.5in'}}><span>&nbsp;</span></p>

  <p><span style={{fontSize: '18.0pt', lineHeight: '115%', color: '#202124'}}>&nbsp;</span></p>

  <div style={{textAlign: 'center'}}>
    <img width={142} height={307} src="del-2.jpg" alt="App screenshot 2" style={{display: 'block', margin: '0 auto'}} />
  </div>

  <p style={{marginLeft: '0.5in'}}><span>&nbsp;</span></p>

  <p style={{marginLeft: '0.5in', textIndent: '-0.25in'}}>
    <span>●<span style={{fontSize: '7.0pt'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
    <span>Confirm your choice.</span>
  </p>

  <div style={{textAlign: 'center'}}>
    <img width={142} height={307} src="del-3.jpg" alt="App screenshot 3" style={{display: 'block', margin: '0 auto'}} />
  </div>

  <p><span>&nbsp;</span></p>

  <p><span>It may take us up to 72 hours to remove your data and account. We will notify you via email once the process is complete.</span></p>

  <p><span>&nbsp;</span></p>

  <p><span>All the personal information we hold about you will be deleted. However, we may retain some of your data(e.g. shower temperature, gallons used) in anonymized form for analytics purposes.</span></p>

  <p><span>&nbsp;</span></p>

  <p><span>We are sorry to see you go! You can come back and create your account again to enjoy the benefits of the hai app anytime in the future.</span></p>
</div>
</>
  );
};

export default DeleteAccount;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Layout from "@components/Layout";

const Loading = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    setTimeout(() => {
      if (dots === '...') {
        setDots('.');
      } else {
        setDots(dots + '.');
      }
    }, 1000);
  }, [dots]);

  useEffect(() => {
    setDots('.');
  }, []);

  return (
    <>
    <Layout>
    <h2 className="text-4xl leading-9 mb-4 text-primary font-medium">
    {'Loading'+dots}
      </h2>
      </Layout>
      </>
  );
};

export default Loading;

import React, { useState } from "react";
import CloseCircle from "@assets/images/close-circle.svg";
import Eye from "@assets/images/eye.svg";
import EyeOff from "@assets/images/eye-off.svg";
import { useFormContext } from "react-hook-form";

const TextField = ({
  label,
  name,
  validations,
  isLastField,
  type = "text",
  bottomAction,
  autocomplete="",
  ...props
}) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const fieldValue = watch(name);
  const error = errors[name];

  const underlineColor = error
    ? "border-danger"
    : fieldValue
    ? "border-primary"
    : "border-grey";
  const underlineColorOnFocus = error
    ? "focus-within:border-danger"
    : "focus-within:border-primary";

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => setPasswordShown(!passwordShown);

  return (
    <div className={!isLastField ? "mb-46px" : ""}>
      <div
        className={`flex items-center relative ${underlineColorOnFocus} border-b-2 pb-0.5 ${underlineColor}`}
      >
        <input
          type={
            type !== "password" ? type : passwordShown ? "text" : "password"
          }
          name={name}
          autocomplete={autocomplete}
          placeholder=" "
          className="block w-full bg-transparent appearance-none focus:outline-none text-primary text-base"
          {...register(name, validations)}
          {...props}
        />
        {((error && fieldValue) || type === "password") && (
          <RightIcon
            error={error}
            name={name}
            type={type}
            setValue={setValue}
            passwordShown={passwordShown}
            togglePasswordVisibility={togglePasswordVisibility}
          />
        )}
        <label
          htmlFor="username"
          className="text-grey text-opacity-80 text-sm absolute top-0 duration-300 origin-0 pointer-events-none"
        >
          {label}
        </label>
      </div>
      <div className="relative">
        {error && <ErrorMessage bottomAction={bottomAction} error={error} />}
        <div className={`absolute right-0 ${error ? "top-0" : "top-1"}`}>
          {bottomAction}
        </div>
      </div>
    </div>
  );
};

const ErrorMessage = ({ error }) => {
  return (
    <div className="text-xs text-danger mt-1 animate__error w-3/4">
      {error.message}
    </div>
  );
};

const RightIcon = ({
  error,
  name,
  type,
  setValue,
  passwordShown,
  togglePasswordVisibility,
}) => {
  const eyeClassName = "ml-2 active:opacity-50 cursor-pointer";
  if (type === "password") {
    if (passwordShown) {
      return (
        <img
          src={EyeOff}
          onClick={togglePasswordVisibility}
          className={eyeClassName}
          alt="show password icon"
        />
      );
    } else {
      return (
        <img
          src={Eye}
          onClick={togglePasswordVisibility}
          className={eyeClassName}
          alt="hide password icon"
        />
      );
    }
  } else if (error) {
    return (
      <img
        src={CloseCircle}
        onClick={() => setValue(name, "", { shouldValidate: true })}
        className="w-13px h-13px ml-1 active:opacity-50 cursor-pointer"
        alt="close-icon"
      />
    );
  }
};

export default TextField;

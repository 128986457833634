import React, { useState } from "react";
import Layout from "@components/Layout";
import FormLayout from "@components/FormLayout";
import Button from "@components/Button";
import TextField from "@components/TextField";
import ForgotPasswordModal from "modals/ForgotPassword";
import { emailValidations } from "@utils/constants";
import { useForm } from "react-hook-form";
import {authBloc} from '@hai/bloc/auth';
import { loginFlow } from "@hai/bloc/nav";

const SignIn = ({ history }) => {
  const methods = useForm({ mode: "all" });
  const [processing, setIsProcessing] = useState(false);

  let [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);

  const openForgotPassword = async () => {
    const isEmailValid = await methods.trigger("email");
    if (isEmailValid) {
      setIsForgotPasswordOpen(true);
    }
  };

  const onFormSubmit = (data) => {
    setIsProcessing(true);
    setTimeout(() => {
      authBloc.logIn(data.email, data.password).catch(error => {
        setIsProcessing(false);
        console.log(error);
        if (error.code === 'UserNotFoundException') {
          methods.setError('email', {
            type: "incorrect",
            message: "Please enter a valid email address.",
          })
        }else if (error.code === 'NotAuthorizedException') {
          methods.setError('password', {
            type: "incorrect",
            message: "Password incorrect",
          })
        }else if (error.code === 'PasswordResetRequiredException') {
          onResetRequest(data.email);
        } 
        else {
          methods.setError('password', {
            type: "incorrect",
            message: "Something went wrong. Please try again!",
          })
        }
      });
    }, 20);
  };

  const onResetRequest = (email) => {
    authBloc.forgotPassword(email, true).catch((e) => {
      if (e.code === "UserNotFoundException") {
        methods.setError("email", {
          type: "incorrect",
          message: "Please enter a valid email address.",
        });
      } else if (e.code === "LimitExceededException") {
        methods.setError("email", {
          type: "incorrect",
          message: "Attempt limit exceeded, please try after some time.",
        });
      } else {
        methods.setError("email", {
          type: "incorrect",
          message: "Something went wrong. Please try again!",
        });
      }
    });
  };

  return (
    <>
      <Layout>
        <FormLayout
          methods={methods}
          onSubmit={onFormSubmit}
          title="Sign in"
          subtitle="Please fill out the information below:"
        >
          {textFields.map((field, index) => {
            const isLastField = index === textFields.length - 1;
            return (
              <TextField
                key={field.name}
                name={field.name}
                label={field.label}
                type={field.type}
                validations={field.validations}
                bottomAction={
                  isLastField && (
                    <ForgotPasswordButton onClick={() => {openForgotPassword()}} />
                  )
                }
                autocomplete={field.autoComplete}
              />
            );
          })}
          <div className="mt-16">
            <Button
              value={processing ? "Signing In" : "Sign In"}
              type="submit"
              disabled={processing}
              processing={processing}
            />
          </div>
          <div className="mt-5">
            <div
              className="text-center text-primary text-base cursor-pointer select-none"
              onClick={() => {loginFlow.signup()}}
            >
              Don’t have an account?
              <span className="font-medium"> Sign up {">"}</span>
            </div>
          </div>
        </FormLayout>
      </Layout>
      <ForgotPasswordModal
        isOpen={isForgotPasswordOpen}
        closeModal={() => setIsForgotPasswordOpen(false)}
        email={methods.getValues('email')}
        accountNotExist={() => {methods.setError('email', {
          type: "incorrect",
          message: "Please enter a valid email address.",
        })}}
        limitExceeded={() => {methods.setError('email', {
          type: "incorrect",
          message: "Limit Exceeded. Try again later.",
        })}}
        invalidUser={() => {methods.setError('email', {
          type: "incorrect",
          message: "Cannot reset password for the user as there is no registered/verified email",
        })}}
      />
    </>
  );
};

const ForgotPasswordButton = ({ onClick }) => (
  <button
    type="button"
    onClick={() => onClick(true)}
    className="text-grey text-xs cursor-pointer active:opacity-50 focus:opacity-50 focus:outline-none"
  >
    Forgot password
  </button>
);

export default SignIn;

const textFields = [
  {
    name: "email",
    label: "Email",
    type: "email",
    value: authBloc.getEmail(),
    validations: emailValidations,
    autoComplete:"username"
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    validations: {
      required: { value: true, message: "Password cannot be blank" },
    },
    autoComplete:"current-password"
  },
];

import React from "react";
import ResetPasswordLayout from "@components/ResetPasswordLayout";
import { loginFlow } from "@hai/bloc/nav";
import { authBloc } from "@hai/bloc/auth";
import { useForm } from "react-hook-form";

const ExpiredPassword = () => {
  const methods = useForm();
  const email = authBloc.getEmail();

  const headerInfo = {
    title: "Your Password has Expired",
    subtitle: 
    `The password has been in use for too long and you must choose a new password at this time. An email with a confirmation code was just sent to ${email}. Please enter it below.`
  };

  return (
    <ResetPasswordLayout
      methods={methods}
      onSuccess={() => loginFlow.cancel()}
      onCancel={() => loginFlow.cancel()}
      headerInfo={headerInfo}
    />
  );
};

export default ExpiredPassword;

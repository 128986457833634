import React from "react";

const Header = ({ title, subtitle }) => {
  return (
    <>
      <h2 className="text-4xl leading-9 mb-4 text-primary font-medium">
        {title}
      </h2>
      <p className="text-base leading-22px text-primary">{subtitle}</p>
    </>
  );
};

export default Header;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormLayout from "@components/FormLayout";
import Layout from "@components/Layout";
import TextField from "@components/TextField";
import Button from "@components/Button";

import ForgotPasswordModal from "modals/ForgotPassword";
import PasswordChangeSuccessful from "modals/PasswordChangeSuccessful";

import { loginFlow, LoginFlow } from "@hai/bloc/nav";
import {authBloc} from '@hai/bloc/auth';

const ChangePassword = ({onSuccess}) => {
  const methods = useForm();
  const [processing, setIsProcessing] = useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const  [isChangeSuccessOpen, setIsChangeSuccessOpen] = useState(false);

  const openForgotPassword = async () => {
    if (authBloc.getEmail()) {
      setIsForgotPasswordOpen(true);
    }
  };

  const onFormSubmit = (data) => {
    setIsProcessing(true);
    authBloc
        .changePassword(data.oldPassword, data.newPassword)
        .then(() => {
            //TODO: redirect to shopify page
            setIsChangeSuccessOpen(true);
        })
        .catch(e => {
            const errorMessage =
                        'Password must have a number, symbol, Upper case and Lower case characters.';
            if(e.code === 'NotAuthorizedException'){
                methods.setError('oldPassword', {
                    type: "incorrect",
                    message: "Password Incorrect.",
                  });
            }else if(e.code === 'InvalidPasswordException'){
                methods.setError('newPassword', {
                    type: "incorrect",
                    message: errorMessage,
                  })
            }else if(e.code === 'InvalidParameterException'){
                const PROPOSED_PASSWORD = "'proposedPassword'";
                const message = e.message.split(' ');
                const index = message.find(item => item === PROPOSED_PASSWORD);
                if (index === PROPOSED_PASSWORD) {
                    methods.setError('newPassword', {
                        type: "incorrect",
                        message: errorMessage,
                      })
                }else{
                    methods.setError('oldPassword', {
                        type: "incorrect",
                        message: errorMessage,
                      });
                }
            }else if(e.code === 'LimitExceededException'){
                methods.setError('oldPassword', {
                    type: "incorrect",
                    message: "Attempt limit exceeded, please try after some time.",
                  })
            }else{
                methods.setError('oldPassword', {
                    type: "incorrect",
                    message: "Something went wrong. Please try again!",
                  });
            }
        })
        .finally(() => {
            setIsProcessing(false);
        });
  }
  return (
    <>
        <Layout>
        <FormLayout
            title="Change Password"
            methods={methods}
            onSubmit={onFormSubmit}
            showDisclaimer={false}
        >
            <TextFieldsList watch={methods.watch} />
            <div className="mt-16">
            <Button
                value={processing ? "Submitting" : "Submit"}
                type="submit"
                disabled={processing}
                processing={processing}
            />
            </div>
            <div className="mt-5">
            <Button
                type="button"
                onClick={openForgotPassword}
                transparent
                value="Forgot Old Password"
                disabled={processing}
            />
            </div>
        </FormLayout>
        </Layout>
        <ForgotPasswordModal
        isOpen={isForgotPasswordOpen}
        closeModal={() => {
            setIsForgotPasswordOpen(false);
        }}
        accountNotExist={() => {
            console.log('enter a valid email address.');
        }}
        limitExceeded={() => {
            console.log('Limit Exceeded. Try again later.');
        }}
        invalidUser={() => {
            console.log('Cannot reset password for the user as there is no registered/verified email');
        }}
        successFlag={()=> {
            loginFlow.notify(LoginFlow.screenConfirmReset);
        }}
        email={authBloc.getEmail()}
      />
      <PasswordChangeSuccessful
        isOpen={isChangeSuccessOpen}
        closeModal={() => {
            setIsChangeSuccessOpen(false); 
            onSuccess();
        }}
      />
    </>
  );
}

const TextFieldsList = ({ watch }) => {
    const textFields = [
      {
        name: "oldPassword",
        label: "Old Password",
        type: "password",
        validations: {
            required: { value: true, message: "Password cannot be blank" },
        },
        autoComplete:"current-password"
      },
      {
        name: "newPassword",
        label: "New Password (8-30 characters)",
        type: "password",
        validations: {
          required: { value: true, message: "Password cannot be blank" },
          minLength: {
            value: 8,
            message: "Password must be 8 to 30 characters.",
          },
          maxLength: {
            value: 30,
            message: "Password must be 8 to 30 characters.",
          },
        },
        autoComplete:"new-password"
      },
      {
        name: "confirmNewPassword",
        label: "Confirm New Password",
        type: "password",
        validations: {
          required: { value: true, message: "Password cannot be blank" },
          validate: (value) => {
            return value === watch("newPassword")
              ? true
              : "Passwords don’t match.";
          },
        },
        autoComplete:"new-password"
      },
    ];
    return textFields.map((field, index) => (
      <TextField
        key={field.name}
        name={field.name}
        label={field.label}
        type={field.type}
        validations={field.validations}
        isLastField={index === textFields.length - 1}
        autocomplete={field.autoComplete}
      />
    ));
  };

export default ChangePassword
import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CheckMarkIcon from "@assets/images/check-mark.svg";
import CloseIcon from "@assets/images/close.svg";

const PasswordChangeSuccessful = ({ isOpen, closeModal }) => {
  const closeButtonRef = useRef(null);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={closeButtonRef}
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="min-h-screen px-16 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-modal" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-90"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
          >
            <div className="inline-block w-full max-w-md py-9 px-5 overflow-hidden text-left align-middle transition-all transform bg-cream shadow-xl">
              <div className="flex flex-col justify-center items-center">
                <img
                  src={CheckMarkIcon}
                  onClick={closeModal}
                  alt="check mark icon"
                />
                <h4 className="text-base text-center leading-5 text-primary mt-4 whitespace-pre-wrap">
                  Password change{"\n"}successful!
                </h4>
              </div>
              <img
                src={CloseIcon}
                className="absolute top-14px right-14px cursor-pointer"
                ref={closeButtonRef}
                onClick={closeModal}
                alt="Close icon"
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PasswordChangeSuccessful;

import React, {useEffect} from "react";
import { HashRouter,MemoryRouter, Switch, Route, useHistory } from "react-router-dom";
import {loginFlow, LoginFlow} from '@hai/bloc/nav';

/* Routes */
import CreateAccount from "@routes/CreateAccount";
import SignIn from "@routes/SignIn";
import Verification from "@routes/Verification";
import ResetPassword from "@routes/ResetPassword";
import Logout from "@routes/Logout";
import Loading from "@routes/Loading";
import ChangePassword from "@routes/ChangePassword";

import "./App.css";
import { Observable } from "@hai/bloc/observable";
import { authBloc, AuthState } from "@hai/bloc/auth";
import {haiApi} from "@hai/bloc/api";
import * as config from "./hai.json";
import queryString from 'query-string';
import ExpiredPassword from "@routes/ExpiredPassword";
import DeleteAccount from "@routes/DeleteAccount";

Observable.logger = console.log;

const envConfig = Object.values(Object.values(config)[0])[0];
const changePasswordPath = "/changepassword";
const loginPath = "/login";
const logoutPath = "/logout";
const delAccountPath = "/deleteaccount";

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route path={loginPath} component={(props)=>{
          return (<MemoryRouter {...props}>
            <LoginComponent {...props}/>
          </MemoryRouter>)
        }} />
        <Route path={logoutPath} component={(props)=>{
          return (<MemoryRouter {...props}>
            <LogoutComponent {...props}/>
          </MemoryRouter>)
        }} />

        <Route path={changePasswordPath} component={(props)=>{
          return (<MemoryRouter {...props}>
            <LoginComponent {...props} initialParams={{ initialScreen: "changePassword" }}/>
          </MemoryRouter>)
        }}/>
        <Route path={delAccountPath} component={(props)=>{
          return (<MemoryRouter {...props}>
            <DeleteAccount/>
          </MemoryRouter>)
        }}/>
      </Switch>
    </HashRouter>
  );
}

function LoginComponent(props) {
  const history = useHistory();
  const params = queryString.parse(props.location.search);
  console.log('Redirect to',params.redirect_to);

  useEffect(() => {
    authBloc.authState.subscribe(onAuthStateChange, this);
    loginFlow.subscribe(onLoginScreenChange, this);
    onAuthStateChange(authBloc.authState.current);
    if(props?.initialParams?.initialScreen)
      onLoginScreenChange(props.initialParams.initialScreen);
    else
      onLoginScreenChange(loginFlow.current);
    return () => {
      loginFlow.unsubscribe(onLoginScreenChange, this);
      authBloc.authState.unsubscribe(onAuthStateChange, this);
    };
  }, []);

  const onAuthStateChange = async(state) =>{
    if(state === AuthState.LoggedIn && !props?.initialParams?.initialScreen){
      console.log('Logged in');
      let success = false;
      let token;
      try {
        token = await haiApi.post('multipass',{ body:
          {
            redirect_to: params.redirect_to || '/account',
          },
        });
        console.log("Multipass token is ", token);
        success = true;
      } catch (error) {
        console.log(`Failed to get multipass token`, error);
      }

      window.location.href = envConfig.shopifyEndpoint+ (success ? '/account/login/multipass/'+token:(params.error_to ?? ''));
    }
    else if(state == AuthState.NotLoggedIn)
      onLoginScreenChange(LoginFlow.screenSignin);
  }

  const onLoginScreenChange = (screen) => {
    console.log('onLoginScreenChange',screen, history?.length);
    if (screen === LoginFlow.screenBrandIntro) {
      loginFlow.continue();
      return;
    }
    else if(screen === LoginFlow.screenLanding){
      if(!authBloc.authState.current || authBloc.authState.current === AuthState.LoggedIn)
        return;
      
      screen = LoginFlow.screenSignin;
    }

    if (screen) {
      let action = false;
      if (screen === LoginFlow.screenConfirmReset) {
        const currentScreen = history.location.pathname;
        if (currentScreen !== screen) action = true;
      } else action = false;
      if(action === true){
        history.push(screen);
      }
      else{
        history.replace(screen);
      }
    }
  };

  const onRedirectUrl = () => {
    if(authBloc.authState.current === AuthState.LoggedIn)
      window.location.href = envConfig.shopifyEndpoint + params.redirect_to;
  }

  return (
      <Switch>
          <Route path={'/'} exact={true} component={Loading} />
          <Route path={'/'+LoginFlow.screenSignin} exact={true} component={SignIn} />
          <Route path={'/'+LoginFlow.screenSignup} exact={true} component={CreateAccount} />
          <Route path={'/'+LoginFlow.screenConfirmSignup} exact={true} component={Verification} />
          <Route path={'/'+LoginFlow.screenConfirmReset} exact={true} component={() => (<ResetPassword onSuccess={onRedirectUrl} />)}/>
          <Route path={changePasswordPath} component={() => (<ChangePassword onSuccess={onRedirectUrl} />)}/>
          <Route path={'/'+LoginFlow.screenConfirmExpired} exact={true} component={() => (<ExpiredPassword />)}/>
      </Switch>
  )
}

function LogoutComponent(props){
  const params = queryString.parse(props.location.search);
  console.log('Redirect to',params?.redirect_to);

  useEffect(() => {
    authBloc.authState.subscribe(onAuthStateChange, this);
    onAuthStateChange(authBloc.authState.current);
    return () => {
      authBloc.authState.unsubscribe(onAuthStateChange, this);
    };
  }, []);

  const onAuthStateChange = (state)=>{
    if(state === AuthState.LoggedIn)
      authBloc.logOut();
    else if(state === AuthState.NotLoggedIn || state === AuthState.LoggedOut){
      console.log('Logged out');
      const param = params?.redirect_to;
      window.location.href = param ? envConfig.shopifyEndpoint + param : envConfig.shopifyEndpoint;
    }
  }

  return (<Logout/>);
}

export default App;

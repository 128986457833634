import React, { useState } from "react";
import FormLayout from "@components/FormLayout";
import Layout from "@components/Layout";
import TextField from "@components/TextField";
import Button from "@components/Button";
import ResendCode from "@components/ResendCode";
import PasswordChangeSuccessful from "modals/PasswordChangeSuccessful";
import { authBloc } from "@hai/bloc/auth";
import { ResendCodeFlow } from "@utils/constants";

const ResetPasswordLayout = ({ methods, onSuccess, onCancel, headerInfo }) => {
  const [processing, setIsProcessing] = useState(false);
  let [isChangeSuccessOpen, setIsChangeSuccessOpen] = useState(false);

  const onFormSubmit = (data) => {
    setIsProcessing(true);
    authBloc
      .resetPassword(data.code, data.newPassword)
      .then(() => {
        setIsProcessing(false);
        setIsChangeSuccessOpen(true);
      })
      .catch((e) => {
        if (e.code === "CodeMismatchException") {
          methods.setError("code", {
            type: "incorrect",
            message:
              "Confirmation code incorrect. Enter the correct code or try again.",
          });
        } else if (e.code === "InvalidPasswordException") {
          methods.setError("newPassword", {
            type: "incorrect",
            message: e.message.split(":")[1],
          });
        } else if (e.code === "LimitExceededException") {
          methods.setError("code", {
            type: "incorrect",
            message: "Attempt limit exceeded, please try after some time.",
          });
        } else {
          methods.setError("newPassword", {
            type: "incorrect",
            message: "Something went wrong. Please try again!",
          });
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <Layout>
      <FormLayout
        title={headerInfo?.title}
        subtitle={headerInfo?.subtitle}
        methods={methods}
        onSubmit={onFormSubmit}
        showDisclaimer={false}
      >
        <TextFieldsList methods={methods} />
        <div className="mt-16">
          <Button
            value={processing ? "Submitting" : "Submit"}
            type="submit"
            disabled={processing}
            processing={processing}
          />
        </div>
        <div className="mt-5">
          <Button
            type="button"
            onClick={() => onCancel()}
            transparent
            value="Cancel"
            disabled={processing}
          />
        </div>
      </FormLayout>
      <PasswordChangeSuccessful
        isOpen={isChangeSuccessOpen}
        closeModal={() => {
          setIsChangeSuccessOpen(false);
          onSuccess();
        }}
      />
    </Layout>
  );
};

const TextFieldsList = ({ methods }) => {
  const onResendCodeError = (message) => {
    methods.setError("code", {
      type: "incorrect",
      message: message,
    });
  };

  const textFields = [
    {
      name: "code",
      label: "Confirmation Code",
      type: "text",
      validations: {
        required: {
          value: true,
          message: "Confirmation code cannot be empty",
        },
      },
    },
    {
      name: "newPassword",
      label: "New Password",
      type: "password",
      validations: {
        required: { value: true, message: "Password cannot be blank" },
        minLength: {
          value: 8,
          message: "Password must be 8 to 30 characters.",
        },
        maxLength: {
          value: 30,
          message: "Password must be 8 to 30 characters.",
        },
      },
      autoComplete:"new-password"
    },
    {
      name: "confirmNewPassword",
      label: "Confirm New Password",
      type: "password",
      validations: {
        required: { value: true, message: "Password cannot be blank" },
        validate: (value) => {
          return value === methods.watch("newPassword")
            ? true
            : "Passwords don’t match.";
        },
      },
      autoComplete:"new-password"
    },
  ];
  return textFields.map((field, index) => (
    <TextField
      key={field.name}
      name={field.name}
      label={field.label}
      type={field.type}
      validations={field.validations}
      isLastField={index === textFields.length - 1}
      bottomAction={
        index === 0 ? (
          <ResendCode
            type={ResendCodeFlow.ResetPassword}
            onError={onResendCodeError}
          />
        ) : null
      }
      autocomplete={field.autoComplete}
    />
  ));
};

export default ResetPasswordLayout;

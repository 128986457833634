import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CloseIcon from "@assets/images/close.svg";
import Button from "@components/Button";
import {authBloc} from '@hai/bloc/auth';

const ForgotPassword = ({ isOpen, closeModal, email, accountNotExist, limitExceeded, invalidUser, successFlag }) => {

  const [processing, setIsProcessing] = useState(false);

  const onClose = isSuccess => {
    closeModal();
    if (isSuccess) {
      successFlag.call(undefined);
    }
  };

  const onSubmit = () => {
    setIsProcessing(true);
      closeModal();
      authBloc
        .forgotPassword(email)
        .then(() => {
          onClose(true);
          setIsProcessing(false);
        })
        .catch(e => {
          if (e.code === 'UserNotFoundException') {
            onClose();
            accountNotExist.call(undefined);
          } else if (e.code === 'LimitExceededException') {
            onClose();
            limitExceeded.call(undefined);
          }
          if (e.code === 'InvalidParameterException') {
            onClose();
            invalidUser.call(undefined);
          }
        })
        .finally(() => {
          setIsProcessing(false);
        });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="min-h-screen px-9 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-modal" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-90"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
          >
            <div className="inline-block w-full max-w-lg p-9 overflow-hidden text-left align-middle transition-all transform bg-cream shadow-xl">
              <Dialog.Title
                as="h2"
                className="text-4xl font-medium leading-10 text-primary"
              >
                Forgot Password
              </Dialog.Title>
              <h4 className="text-base text-primary mt-13px">
                Send me an email with password reset instructions.
              </h4>
              <div className="mt-10">
                <Button
                  type="button"
                  value={processing ? "Sending..." : "Yes, send email"}
                  onClick={onSubmit}
                  disabled={processing}
                  processing={processing}
                />
                <Button
                  type="button"
                  value="Oh, no, nevermind."
                  onClick={!processing ? closeModal : null}
                  disabled={processing}
                  outline
                />
              </div>
              <img
                src={CloseIcon}
                className={`absolute top-14px right-14px cursor-pointer ${
                  processing ? "opacity-50" : "opacity-100"
                }`}
                onClick={!processing ? closeModal : null}
                alt="Close icon"
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ForgotPassword;

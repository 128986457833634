import React from "react";
import Header from "@components/Header";
import { FormProvider } from "react-hook-form";

const FormLayout = ({
  onSubmit,
  methods,
  title,
  subtitle,
  children,
  showDisclaimer = true,
}) => {
  return (
    <FormProvider {...methods}>
      <Header title={title} subtitle={subtitle} />
      <form
        className="mt-14"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
      >
        {children}
        {showDisclaimer && (
          <div className="mt-9 text-xs text-grey text-center opacity-80">
            By creating/signing into an account, you agree to our terms of
            service, privacy policy, and membership terms.
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default FormLayout;

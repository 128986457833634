import React from "react";

const CheckboxField = ({ value, onChange, label, name }) => {
  const triggerChange = () => onChange(!value);
  return (
    <div
      className="flex items-center cursor-pointer select-none"
      onClick={triggerChange}
      tabIndex={0}
      onKeyUp={(e) => {
        //Handle space key for accessibility
        e.key === " " && triggerChange();
      }}
    >
      <CheckBox value={value} />
      <div className="text-primary text-sm leading-4">{label}</div>
    </div>
  );
};

const CheckBox = ({ value }) => (
  <div className="w-4 h-4 p-1px border border-primary mr-2">
    <div
      className={`transform transition ${
        value ? "scale-100" : "scale-0"
      } bg-primary w-full h-full duration-50`}
    ></div>
  </div>
);

export default CheckboxField;

import React, { useState } from "react";
import { authBloc } from "@hai/bloc/auth";
import { ResendCodeFlow } from "@utils/constants";

const ResendCode = ({type, onError}) => {
  const [codeSent, setCodeSent] = useState(false);
  const [sending, setSending] = useState(false);

  const sendCode = () => {
    setSending(true);
    switch (type) {
      case ResendCodeFlow.SignUp:
        authBloc.resendSignup().then(() => {
          setStatus();
        })
        .catch(e => {
          if(e && e.message)
            onError(e.message);
        });
       break;
      case ResendCodeFlow.ResetPassword: 
        authBloc.forgotPassword().then(() => {
          setStatus();
        })
        .catch(e => {
          if(e && e.code)
          onError(e.code);
        });
        break;
    }
  };

  const setStatus = () => {
    setCodeSent(true);
    setSending(false);
    setTimer();
  }

  const setTimer = () => {
    // User will be able to resend the code again after 30 seconds
    setTimeout(() => {
      setCodeSent(false);
    }, 30000);
  };

  return (
    <button
      onClick={!codeSent ? sendCode : null}
      type="button"
      className={`text-xs leading-3 font-medium focus:outline-none ${
        codeSent ? "text-primary" : "text-grey"
      } ${
        !codeSent && !sending
          ? "cursor-pointer active:opacity-50"
          : "cursor-auto active:opacity-100"
      }`}
    >
      {sending ? "Resending..." : codeSent ? "Code Resent" : "Resend Code"}
    </button>
  );
};

export default ResendCode;
